import { PlusOutlined } from "@ant-design/icons";
import { Alert, Box, Button, Grid } from "@mui/material";
import _ from "lodash";
import React, { ReactNode } from "react";

import TimelineSkeleton from "components/form/forms/sections/ActivityTimeline/TimelineSkeleton";

export function ActivityTimeline({
  children,
  addNewActivity,
  activityTimelineFetchError,
  activityTimelineRefetch,
  activityTimelineLoading,
}: {
  children: ReactNode;
  addNewActivity: () => void;
  activityTimelineFetchError: boolean;
  activityTimelineLoading: boolean;
  activityTimelineRefetch: () => void;
}) {
  return (
    <>
      {activityTimelineLoading ? (
        <TimelineSkeleton />
      ) : !_.isEmpty(children) ? (
        <Grid
          container
          spacing={3}
          alignItems="center"
          sx={{
            position: "relative",
            "&>*": {
              position: "relative",
              zIndex: "5",
            },
            "&:after": {
              content: '""',
              position: "absolute",
              top: 8,
              left: 43,
              width: 2,
              height: "100%",
              background: "#ebebeb",
              zIndex: "1",
            },
          }}
        >
          {children}
        </Grid>
      ) : (
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "100%",
            alignItems: "center",
            justifyContent: "center",
            gap: "16px",
          }}
        >
          {activityTimelineFetchError && (
            <Alert
              severity="warning"
              action={
                <Button
                  color="primary"
                  size="small"
                  onClick={activityTimelineRefetch}
                  sx={{ fontWeight: 600, textTransform: "none" }}
                >
                  Try Again
                </Button>
              }
            >
              An error occurred while loading the Contact Timeline
            </Alert>
          )}
          <Button
            variant="outlined"
            startIcon={<PlusOutlined />}
            onClick={addNewActivity}
          >
            Add
          </Button>
        </Box>
      )}
    </>
  );
}
