import { Box, Skeleton, Stack } from "@mui/material";
import React from "react";

const TimelineSkeleton = () => {
  return (
    <Stack spacing={3} sx={{ maxWidth: 600 }}>
      <Box display="flex" alignItems="flex-start">
        {/* Left Icon */}
        <Box mr={2}>
          <Skeleton variant="circular" width={40} height={40} />
        </Box>
        {/* Content Skeleton */}
        <Box flex={1}>
          <Skeleton variant="text" width="50%" height={24} />
          <Skeleton variant="text" width="70%" height={16} />
          <Skeleton variant="text" width="90%" height={16} />
          <Skeleton variant="text" width="80%" height={16} />
        </Box>
      </Box>
    </Stack>
  );
};

export default TimelineSkeleton;
