import { TableRow, TableCell } from "@mui/material";
import { Skeleton } from "@mui/material";
import React from "react";

const TableSkeleton = ({
  colSpan,
  skeletonRowCount = 5,
}: {
  colSpan: number;
  skeletonRowCount?: number;
}) => {
  return (
    <>
      {Array.from({ length: skeletonRowCount }).map((_, index) => (
        <TableRow key={index}>
          {Array.from({ length: colSpan }).map((_, index) => (
            <TableCell colSpan={1} key={index}>
              <Skeleton key={index} height="100%" width="100%" sx={{ my: 1 }} />
            </TableCell>
          ))}
        </TableRow>
      ))}
    </>
  );
};

export default TableSkeleton;
