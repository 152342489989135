import { BaseTable } from "types/api/user_management/schemaAddOns";

export enum MessageType {
  OUTLOOK_EMAIL_INTEGRATION_ERROR = "outlook_email_integration_error",
  OUTLOOK_CONTACT_INTEGRATION_ERROR = "outlook_contact_integration_error",
  GOOGLE_EMAIL_INTEGRATION_ERROR = "google_email_integration_error",
  GOOGLE_CONTACT_INTEGRATION_ERROR = "google_contact_integration_error",
}

export interface UserNotification extends BaseTable {
  id: number;
  user_id: number;
  message_type: MessageType;
  message_details?: string;
  enabled: boolean;
}
