import { useTheme } from "@mui/material/styles";
import { useMemo } from "react";

import ScrollX from "components/ScrollX";
import PipelineOverviewTable from "components/table/PipelineOverviewTable";
import { useDealOrgPreferences } from "contexts/DealOrgPreferencesContext";
import { DEFAULT_TABLE_SORT } from "pages/deal/constants/dealFilters";
import { DealTableColumns } from "pages/deal/sections/constants/dealTable";
import { Deal, DealExtended } from "types/deal";
import { DealStats } from "types/dealReporting";

interface DealTableProps {
  data: Deal[] | DealExtended[] | Partial<DealExtended>[];
  stats?: DealStats;
  defaultPageSize?: number;
  showColumns: string[];
  onRowsChange: (rows: any) => void;
  showAdd?: boolean;
  showAggregate?: boolean;
  showWidget?: boolean;
  usersDisplay: { [option: string]: string } | null;
  skipPageReset: boolean;
  sortBy?: any;
  syncSort?: boolean;
  tableSize?: "small" | "medium" | undefined;
  resizeColumns?: boolean;
  isDataLoading?: boolean;
}

const DealTable = ({
  data,
  stats,
  defaultPageSize = 10,
  showColumns,
  onRowsChange,
  showAdd = false,
  showAggregate = false,
  showWidget = false,
  usersDisplay,
  skipPageReset,
  sortBy = DEFAULT_TABLE_SORT,
  syncSort,
  tableSize,
  resizeColumns = false,
  isDataLoading = false,
}: DealTableProps) => {
  const theme = useTheme();

  const { data: dealOrgPreferences } = useDealOrgPreferences();

  const columns = useMemo(
    () => DealTableColumns(usersDisplay, dealOrgPreferences, theme),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [theme, usersDisplay, dealOrgPreferences]
  );

  return (
    <ScrollX>
      <PipelineOverviewTable
        // @ts-ignore
        columns={columns}
        data={data}
        isDataLoading={isDataLoading}
        stats={stats}
        defaultPageSize={defaultPageSize}
        filters={[]}
        getHeaderProps={(column: any) => column.getSortByToggleProps()}
        showColumns={showColumns}
        onRowsChange={onRowsChange}
        showAdd={showAdd}
        showAggregate={showAggregate}
        showFiltering={false}
        showWidget={showWidget}
        initialSortBy={sortBy}
        syncSort={syncSort}
        skipPageReset={skipPageReset}
        usersDisplay={usersDisplay}
        tableSize={tableSize}
        resizeColumns={resizeColumns}
      />
    </ScrollX>
  );
};

export default DealTable;
