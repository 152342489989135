import { InfoCircleFilled } from "@ant-design/icons";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import {
  Alert,
  Box,
  FormControl,
  Grid,
  IconButton,
  Link,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
} from "@mui/material";
import { FieldArray, FormikValues, useField, useFormikContext } from "formik";
import _, { get, isNil, map, size, sumBy } from "lodash";
import * as React from "react";
import { Fragment, useMemo } from "react";

import { CreoneField, FieldLabel } from "components/form/basic/creone_field";
import { DealFormTooltip } from "components/form/DealFormTooltip";
import { SelectOption } from "components/form/SelectOption";
import { TextFieldCurrency } from "components/form/TextFieldCurrency";
import { TextFieldPercent } from "components/form/TextFieldPercent";
import { DealMetadata } from "constants/objectMetadata/dealMetadata";
import { UserLookupFieldWithOther } from "pages/deal/components/LookupField";
import { BROKER_ROLE_OPTIONS } from "pages/deal/constants/deal_enums";
import { isClosed } from "pages/deal/utils/deal_form";
import { formatCurrency } from "pages/deal/utils/reporting";
import { CommissionSplitCreate } from "types/api/deal/commissionSplit";
import { DealStatus } from "types/deal";

interface SplitMessageProps {
  onClick: () => void;
  commissionVal: number;
  amountSum: number;
  type: "Estimated" | "Actual";
}

const SplitMessage: React.FC<SplitMessageProps> = ({
  commissionVal,
  amountSum,
  onClick,
  type,
}: SplitMessageProps) => {
  const remainder = commissionVal - amountSum;
  const isUnallocated = remainder > 0;
  const action = isUnallocated
    ? "allocate this amount to"
    : "subtract this amount from";

  return (
    <Alert color="error" icon={<InfoCircleFilled />}>
      {type} Commission is {formatCurrency(commissionVal)}. The {type} Splits
      above add up to {formatCurrency(amountSum)}.{" "}
      {formatCurrency(isUnallocated ? remainder : -remainder)} is{" "}
      {isUnallocated ? "unallocated" : "over-allocated"}.{" "}
      <Link onClick={onClick} sx={{ cursor: "pointer" }}>
        Click here{"\u00A0"}
      </Link>
      to {action} the last person.
    </Alert>
  );
};

export function CommissionSplitSection() {
  const [field, meta, helpers] = useField("commission_split");
  const [actualCommissionField, ,] = useField(
    DealMetadata.commission.fieldName
  );
  const [estimatedCommissionField, ,] = useField(
    DealMetadata.commission_est.fieldName
  );
  const [isPercentField, , isPercentHelpers] = useField(
    "commission_split_percent_toggle"
  );

  const { values, setFieldValue, validateField, setValues } =
    useFormikContext<FormikValues>();

  const { submitCount } = useFormikContext();

  const actualAmountSum = useMemo(
    () => sumBy(field.value, "actual_amount") ?? 0,
    [field.value]
  );
  const actualPercentSum = useMemo(
    () => sumBy(field.value, "actual_percent") ?? 0,
    [field.value]
  );
  const actualCommissionVal = useMemo(
    () => actualCommissionField.value ?? 0,
    [actualCommissionField.value]
  );

  const estimatedAmountSum = useMemo(
    () => sumBy(field.value, "estimate_amount") ?? 0,
    [field.value]
  );
  const estimatedPercentSum = useMemo(
    () => sumBy(field.value, "estimate_percent") ?? 0,
    [field.value]
  );
  const estimatedCommissionVal = useMemo(
    () => estimatedCommissionField.value ?? 0,
    [estimatedCommissionField.value]
  );

  const handleSplitRemainder = (
    targetSum: number,
    currentSum: number,
    fieldName:
      | "estimate_amount"
      | "estimate_percent"
      | "actual_amount"
      | "actual_percent"
  ) => {
    const remainder = targetSum - currentSum;
    const addToIndex = field.value.length - 1;

    if (size(field.value)) {
      const fullFieldName = `commission_split.${addToIndex}.${fieldName}`;
      const currentValue = get(values, fullFieldName);
      setFieldValue(fullFieldName, currentValue + remainder).then(() => {
        validateField("commission_split");
      });
    }
  };

  const handleIsPercentChange = async (
    e: unknown,
    newIsPercentValue: boolean
  ) => {
    if (!isNil(newIsPercentValue)) {
      // Get the previous value
      const prevIsPercentValue = isPercentField.value;
      if (!isNil(prevIsPercentValue)) {
        // Stage update for a single change affecting all values
        await setValues((prevValues) => {
          // Check if any updates to the percent or amount fields is necessary
          const newCommissionSplitValue = map(
            prevValues.commission_split,
            (x) => {
              // Clone the object to ensure immutability
              const updatedItem = { ...x };

              // Sync amount or percent if necessary
              if (prevIsPercentValue) {
                // Previous value was Percent. Sync Amount from Percent.
                // Update estimate amount
                if (
                  !isNil(prevValues.commission_est) &&
                  !isNil(x.estimate_percent)
                ) {
                  updatedItem.estimate_amount =
                    x.estimate_percent * prevValues.commission_est;
                }
                // Update actual amount
                if (!isNil(prevValues.commission) && !isNil(x.actual_percent)) {
                  updatedItem.actual_amount =
                    x.actual_percent * prevValues.commission;
                }
              } else {
                // Previous value was Amount. Sync Percent from Amount.
                // Update estimate percent
                if (
                  !isNil(prevValues.commission_est) &&
                  !isNil(x.estimate_amount) &&
                  prevValues.commission_est !== 0
                ) {
                  updatedItem.estimate_percent =
                    x.estimate_amount / prevValues.commission_est;
                }
                // Update actual percent
                if (
                  !isNil(prevValues.commission) &&
                  !isNil(x.actual_amount) &&
                  prevValues.commission !== 0
                ) {
                  updatedItem.actual_percent =
                    x.actual_amount / prevValues.commission;
                }
              }
              return updatedItem;
            }
          );
          return {
            ...prevValues,
            commission_split_percent_toggle: newIsPercentValue,
            commission_split: newCommissionSplitValue,
          };
        });
      } else {
        await isPercentHelpers.setValue(newIsPercentValue);
      }
    }
  };

  return (
    <Box>
      <Stack spacing={2} sx={{ my: 1 }}>
        <CreoneField
          fieldName={"commission_split_percent_toggle"}
          displayName={"Commission Split"}
          isVerticalLabel={true}
        >
          <ToggleButtonGroup
            color="primary"
            id={"commission_split_percent_toggle"}
            value={isPercentField.value}
            exclusive
            onChange={handleIsPercentChange}
            aria-label={"commission_split_percent_toggle"}
          >
            <ToggleButton
              id={`${"commission_split_percent_toggle"}-dollar`}
              size="small"
              value={false}
              aria-label="left aligned"
            >
              $
            </ToggleButton>
            <ToggleButton
              id={`${"commission_split_percent_toggle"}-percent`}
              size="small"
              value={true}
              aria-label="centered"
            >
              %
            </ToggleButton>
          </ToggleButtonGroup>
        </CreoneField>
      </Stack>
      <FieldArray name={"commission_split"}>
        {({ push, handleRemove }) => (
          <Grid
            container
            columnSpacing={2}
            rowSpacing={1}
            alignItems={"stretch"}
          >
            <Grid item xs={3}>
              <FieldLabel
                required={true}
                disabled={false}
                displayName={"Broker Name"}
                fieldName={"user"}
              />
            </Grid>
            <Grid item xs={2}>
              <FieldLabel
                required={
                  field.value.length > 0 &&
                  _.get(values, "status") !== DealStatus.closed
                }
                disabled={field.value.length <= 1}
                displayName={"Estimated Split"}
                fieldName={"Estimated Split"}
              />
            </Grid>
            <Grid item xs={2}>
              <FieldLabel
                required={
                  field.value.length > 0 &&
                  _.get(values, "status") === DealStatus.closed
                }
                disabled={field.value.length <= 1}
                displayName={"Actual Split"}
                fieldName={"Actual Split"}
              />
            </Grid>
            <Grid item xs={3}>
              <FieldLabel
                required={false}
                disabled={false}
                displayName={"Role"}
                fieldName={"Role"}
              />
            </Grid>
            {field.value.length > 0 &&
              field.value.map((row: CommissionSplitCreate, index: number) => {
                return (
                  <Fragment key={index}>
                    <Grid item xs={3}>
                      <UserLookupFieldWithOther
                        fieldName={`commission_split.${index}.user`}
                        showLabel={false}
                        showError={false}
                      />
                    </Grid>
                    <Grid item xs={2}>
                      {isPercentField.value ? (
                        <TextFieldPercent
                          fieldName={`commission_split.${index}.estimate_percent`}
                          showLabel={false}
                        />
                      ) : (
                        <TextFieldCurrency
                          fieldName={`commission_split.${index}.estimate_amount`}
                          showLabel={false}
                        />
                      )}
                    </Grid>
                    <Grid item xs={2}>
                      <DealFormTooltip
                        active={!isClosed(values)}
                        message={`Deal needs to have a "Closed" status.`}
                      >
                        <FormControl>
                          {isPercentField.value ? (
                            <TextFieldPercent
                              fieldName={`commission_split.${index}.actual_percent`}
                              showLabel={false}
                              disabled={!isClosed(values)}
                            />
                          ) : (
                            <TextFieldCurrency
                              fieldName={`commission_split.${index}.actual_amount`}
                              showLabel={false}
                              disabled={!isClosed(values)}
                            />
                          )}
                        </FormControl>
                      </DealFormTooltip>
                    </Grid>
                    <Grid item xs={3}>
                      <SelectOption
                        options={BROKER_ROLE_OPTIONS}
                        showLabel={false}
                        displayName={"Role"}
                        fieldName={`commission_split.${index}.broker_role`}
                      />
                    </Grid>
                    <Grid item>
                      <Stack direction={"row"}>
                        {field.value.length > 1 && (
                          <IconButton onClick={handleRemove(index)}>
                            <RemoveCircleIcon />
                          </IconButton>
                        )}
                        {index + 1 === field.value.length && (
                          <IconButton
                            onClick={() => {
                              push({
                                user: null,
                                broker_role: "none",
                                estimate_percent: null,
                                estimate_amount: null,
                                actual_percent: null,
                                actual_amount: null,
                              });
                            }}
                          >
                            <AddCircleIcon />
                          </IconButton>
                        )}
                      </Stack>
                    </Grid>
                  </Fragment>
                );
              })}
          </Grid>
        )}
      </FieldArray>
      {!!meta.error &&
        meta.touched &&
        submitCount > 0 &&
        typeof meta.error === "string" && (
          <Grid container sx={{ pt: 2 }}>
            {meta.error === "estimate-split-total-100" ? (
              isPercentField.value ? (
                <SplitMessage
                  onClick={() =>
                    handleSplitRemainder(
                      1,
                      estimatedPercentSum,
                      "estimate_percent"
                    )
                  }
                  amountSum={estimatedPercentSum * estimatedCommissionVal}
                  commissionVal={estimatedCommissionVal}
                  type={"Estimated"}
                />
              ) : (
                <SplitMessage
                  onClick={() =>
                    handleSplitRemainder(
                      estimatedCommissionVal,
                      estimatedAmountSum,
                      "estimate_amount"
                    )
                  }
                  amountSum={estimatedAmountSum}
                  commissionVal={estimatedCommissionVal}
                  type={"Estimated"}
                />
              )
            ) : meta.error === "actual-split-total-100" ? (
              isPercentField.value ? (
                <SplitMessage
                  onClick={() =>
                    handleSplitRemainder(1, actualPercentSum, "actual_percent")
                  }
                  amountSum={actualPercentSum * actualCommissionVal}
                  commissionVal={actualCommissionVal}
                  type={"Actual"}
                />
              ) : (
                <SplitMessage
                  onClick={() =>
                    handleSplitRemainder(
                      actualCommissionVal,
                      actualAmountSum,
                      "actual_amount"
                    )
                  }
                  amountSum={actualAmountSum}
                  commissionVal={actualCommissionVal}
                  type={"Actual"}
                />
              )
            ) : (
              <Alert color="error" icon={<InfoCircleFilled />}>
                {meta.error}
              </Alert>
            )}
          </Grid>
        )}
    </Box>
  );
}
