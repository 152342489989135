import { useQuery } from "react-query";

import { UserNotification } from "types/api/user_management/user_notification";
import { axiosUserServices } from "utils/axios";

export const getUserNotificationsQueryKey = () => ["user_notifications"];

export const useUserNotificationsQuery = () => {
  const query = useQuery<UserNotification[]>({
    queryKey: getUserNotificationsQueryKey(),
    queryFn: () =>
      axiosUserServices.get(`/user/notifications/`).then((response) => {
        return response.data;
      }),
  });

  return query;
};
